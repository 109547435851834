import './App.css';
import { LandingPage } from "./landing-page";

function App() {
  return (
    <LandingPage />
  );
}

export default App;
